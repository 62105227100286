import * as React from "react"

import Layout from "../components/layout"
import Layouttraining from "../components/layouttraining"
import Seo from "../components/seo"

const OnLineTrainings = () => {
  let title = "Online edzések felnőtteknek"
  let subTitle = "Hogy otthon is sportolhass"
  let places = [
    {
      place: "Facebookon - Gabi Online Fitness csoport",
      date: "Heti 2-3x 60 perc élő edzés adott időpontokban",
    },
    {
      place: "Google meet/Zoom - Online személyi edzés",
      date: "Előre egyeztett időpontokban",
    },
  ]
  let description = (
    <span>
      Nincs mindig időnk, kedvünk vagy lehetőségünk elmenni egy edzőterembe. A
      pandémia is rámutatott arra, hogy milyen sokan szeretnének otthon edzeni,
      s ha csak <b> virtuálisan is de egy csoportos vagy személyi edzésen </b>
      részt venni. OnLine edzéseim dinamikusak, kemények, változatosak.
      <b>Csoportos óráimon havi előfizetéses rendszerben vehetsz részt </b>az
      élő edzéseken vagy megnézheted azokat utólag is. Csupán egy Facebook fiók
      kell hozzá és máris csatlakozhatsz is! Online személyi edzésre is van
      lehetőséged Google meeten vagy Zoomon keresztül.
    </span>
  )
  let imageSrc = "online"

  return (
    <Layout>
      <Seo title={title} />
      <Layouttraining
        title={title}
        subTitle={subTitle}
        description={description}
        places={places}
        imageSrc={imageSrc}
      />
    </Layout>
  )
}

export default OnLineTrainings
